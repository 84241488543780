









































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import html2pdf from 'html2pdf.js'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import TagList from '@bertazzoni/back/components/TagList.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import StatsService from '@bertazzoni/common/src/services/StatsService'
import EntitiesService from '@bertazzoni/common/src/services/EntitiesService'
import { EntityType } from '@bertazzoni/common/src/models/entity.model'
import { statsFilter, rankedModule, rankedContent } from '@bertazzoni/common/src/models/stats.model'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { RankedTag } from '@bertazzoni/common/src/models/tag.model'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'
import moment from 'moment'
import ModulesService from '@bertazzoni/common/src/services/ModulesService'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import { STAT_ANALITYCS } from '@bertazzoni/common/src/helpers/utils/utils'
import { UserType } from '@bertazzoni/common/src/models/user.model'

type rankedTagPaginated = {
  tagCategory: string
  tagName: string
  attachedContent: number
  nbOfSearch: number
}

@Component({
  components: { Loader, TagList, Icons, DatePicker, PaginatedTable }
})
export default class StatsContainer extends Vue {
  private pageLoaded = false
  private loading = false
  private keyFilter = 0
  private keyPaginated = 0
  private dateList: string[] = ['1 day', '1 month', '1 year', 'ever']
  private dateCursor = '1 month'
  private moduleStat: {
    language?: string
    completed: number
    avgScore: number
    available: number
  }[] = []
  private userStat = { total: 0, active: 0, inactive: 0 }
  private tableInfoTag: TableInfo = new TableInfo()
  private tableInfoModule: TableInfo = new TableInfo()
  private rankedModule: rankedModule[]
  private rankedContent: rankedContent[] = []
  private rankedTags: rankedTagPaginated[]
  private tableInfoContent: TableInfo = new TableInfo()
  private currentUser = this.$store.getters['User/currentUser']
  private entityList = []
  private statsFilter: statsFilter = {}
  private showButton = null

  async created(): Promise<void> {
    this.statsFilter.dateCursor = new Date(
      moment()
        .subtract(1, 'month')
        .calendar()
    )
    await this.setAutocomplete()
    await this.searchAllStats()
    this.setTablesInfo()
    this.pageLoaded = true
    this.showButton =
      this.currentUser.type == UserType.bertazzoniAdmin ||
      this.currentUser.type == UserType.countryAdmin
        ? true
        : false
  }
  setTablesInfo(): void {
    this.tableInfoTag.getCustomTable().columns = [
      'Tag category',
      'Tag name',
      'Attached content',
      'Nb of time searched'
    ]
    this.tableInfoTag.getCustomTable().displayTitle = true
    this.tableInfoTag.getCustomTable().displayButton = false
    this.tableInfoTag.getCustomTable().isPaginated = false

    this.tableInfoModule.getCustomTable().isPaginated = false
    this.tableInfoModule.getCustomTable().displayButton = false

    this.tableInfoContent.getCustomTable().isPaginated = false
    this.tableInfoContent.getCustomTable().displayButton = false
  }
  async setAutocomplete(): Promise<void> {
    try {
      if (this.currentUser.linkedEntityId[0] === 'bertazzoni') {
        this.entityList = this.entityList.concat(
          await EntitiesService.findEntitiesByType(
            EntityType.country,
            this.currentUser.linkedEntityId[0]
          )
        )
      } else {
        this.entityList.push(this.currentUser.linkedEntityId[0])
      }
      this.entityList = this.entityList.concat(
        await EntitiesService.findEntitiesByType(
          EntityType.distributor,
          this.currentUser.linkedEntityId[0]
        )
      )
      this.entityList = this.entityList.concat(
        await EntitiesService.findEntitiesByType(
          EntityType.dealer,
          this.currentUser.linkedEntityId[0]
        )
      )
    } catch {
      this.entityList = []
    }
  }
  async searchRankedModule(): Promise<void> {
    try {
      this.rankedModule = await StatsService.rankingModules(this.statsFilter)
      this.rankedModule = this.rankedModule.slice(0, 5)
      // const modules = await ModulesService.getNamesByIds(
      //   this.rankedModule.map((rankMod: rankedModule) => rankMod._id)
      // )
      // modules.map(async (mod: { name: string; id: string }) => {
      //   this.rankedModule.map(async (rankMod: rankedModule) => {
      //     if (mod.id === rankMod._id) rankMod.title = mod.name
      //   })
      // })
      this.keyPaginated++
    } catch {
      EventBus.$emit('close', 0)
      this.rankedModule = []
    }
  }
  async searchRankedContent(): Promise<void> {
    try {
      this.rankedContent = await StatsService.rankingContents(this.statsFilter)
      this.rankedContent = this.rankedContent.slice(0, 5)
      this.keyPaginated++
    } catch {
      EventBus.$emit('close', 0)
      this.rankedContent = []
    }
  }
  async searchRankedTags(): Promise<void> {
    this.rankedTags = []
    try {
      const tagStat = await StatsService.rankingTags(this.statsFilter)
      tagStat.map((stat: RankedTag, index) => {
        if (index === 0) this.addTagToRanked(stat)
        let tagAlreadyIn = null
        this.rankedTags.map((tag: rankedTagPaginated) => {
          if (tag.tagCategory === stat._id.tagCategory && tag.tagName === stat._id.tagName) {
            //Si le tag est déja dans le ranked table
            tagAlreadyIn = tag
          }
        })
        if (tagAlreadyIn) {
          if (stat._id.type === 'tag_assign') tagAlreadyIn.attachedContent = stat.total
          else tagAlreadyIn.nbOfSearch = stat.total
        } else {
          this.addTagToRanked(stat)
        }
      })
      this.rankedTags = this.rankedTags.slice(0, 5)
      this.keyPaginated++
    } catch {
      EventBus.$emit('close', 0)
      this.rankedTags = []
    }
  }

  async addTagToRanked(stat: RankedTag): Promise<void> {
    this.rankedTags.push({
      tagCategory: stat._id.tagCategory,
      tagName: stat._id.tagName,
      attachedContent: stat._id.type === 'tag_assign' ? stat.total : 0,
      nbOfSearch: stat._id.type === 'tag_search' ? stat.total : 0
    })
  }
  async searchAllStats(): Promise<void> {
    this.changeLoading()
    EventBus.$emit('close', 'all')
    this.moduleStat = []
    await this.searchRankedModule()
    await this.searchRankedContent()
    await this.searchRankedTags()
    if (this.statsFilter.filteredEntities && this.statsFilter.filteredEntities.length > 0) {
      this.setStatWithoutFilter()
    } else {
      this.setStatWithFilter()
    }
    this.changeLoading()
  }

  async setStatWithFilter(): Promise<void> {
    try {
      const completed = StatsService.allModulesCompleted(this.statsFilter)
      const avgScore = StatsService.allModulesAvgScore(this.statsFilter)
      const available = StatsService.allModulesCountAvailable(this.statsFilter)
      const usersActivity = StatsService.usersActivity(this.statsFilter)
      const usersTotal = StatsService.usersTotal(this.statsFilter)
      const data = await Promise.all([completed, avgScore, available, usersActivity, usersTotal])
      this.moduleStat.push({ completed: data[0], avgScore: data[1], available: data[2] })
      this.userStat.active = data[3].active
      this.userStat.inactive = data[3].inactive
      this.userStat.total = data[4]
    } catch {
      EventBus.$emit('close', 0)
      this.userStat = { total: 0, active: 0, inactive: 0 }
      this.moduleStat.push({
        completed: 0,
        avgScore: 0,
        available: 0
      })
    }
  }

  async setStatWithoutFilter(): Promise<void> {
    let errorUser = false
    try {
      const userStat = await StatsService.usersActivity(this.statsFilter)
      this.userStat.active = userStat.active
      this.userStat.inactive = userStat.inactive
      this.userStat.total = await StatsService.usersTotal(this.statsFilter)
    } catch {
      this.userStat = { total: 0, active: 0, inactive: 0 }
      errorUser = true
    }
    let countLanguages = []
    try {
      countLanguages = await StatsService.allModulesCountByLanguage(this.statsFilter)
    } catch {
      if (errorUser) EventBus.$emit('close', 0)
      this.moduleStat.push({
        completed: 0,
        avgScore: 0,
        available: 0
      })
    }
    countLanguages.map(async (countByLang: { _id: string; count: number }) => {
      try {
        this.statsFilter.language = JSON.parse(JSON.stringify(countByLang._id))
        this.moduleStat.push({
          language: countByLang._id,
          available: countByLang.count,
          completed: await StatsService.allModulesCompleted(this.statsFilter),
          avgScore: await StatsService.allModulesAvgScore(this.statsFilter)
        })
      } catch {
        EventBus.$emit('close', 0)
        this.moduleStat.push({
          completed: 0,
          avgScore: 0,
          available: countByLang.count,
          language: countByLang._id
        })
      }
    })
    delete this.statsFilter.language
  }
  async selectDealer(dealer: string): Promise<void> {
    this.statsFilter.filteredEntities
      ? this.statsFilter.filteredEntities.push(dealer)
      : (this.statsFilter.filteredEntities = [dealer])
    this.keyFilter++
    await this.searchAllStats()
  }
  async removeDealer(dealer: string): Promise<void> {
    this.statsFilter.filteredEntities.splice(this.statsFilter.filteredEntities.indexOf(dealer), 1)
    if (this.statsFilter.filteredEntities.length === 0) delete this.statsFilter.filteredEntities
    this.keyFilter++
    await this.searchAllStats()
  }
  async resetAllDealer(): Promise<void> {
    delete this.statsFilter.filteredEntities
    this.keyFilter++
    await this.searchAllStats()
  }
  changeLoading(): void {
    this.loading = !this.loading
  }
  exportStat(): void {
    html2pdf(this.$refs.stats, {
      margin: 1,
      filename: 'Export Stats Bertazzoni.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    })
    // const element = document.getElementById('stats')
    // const options = {
    //   filename: 'Export Stats Bertazzoni.pdf',
    //   overrideWidth: '1200'
    // }
    // domToPdf(element, options, function() {
    // })
  }
  goAnalytics(): void {
    window.open(STAT_ANALITYCS)
  }
  @Watch('dateCursor')
  async newDate(newValue: string) {
    if (newValue === 'ever') {
      if (this.statsFilter.dateCursor) delete this.statsFilter.dateCursor
    } else {
      switch (newValue) {
        case '1 day':
          this.statsFilter.dateCursor = new Date(
            moment()
              .subtract(1, 'day')
              .format('YYYY,MM-DD HH:mm:ss')
          )
          break
        case '1 month':
          this.statsFilter.dateCursor = new Date(
            moment()
              .subtract(1, 'month')
              .calendar()
          )
          break
        default:
          this.statsFilter.dateCursor = new Date(
            moment()
              .subtract(1, 'years')
              .calendar()
          )
          break
      }
    }
    await this.searchAllStats()
  }
}
